import React, { Component } from "react";
import "../../scss/bootstrap/_grid.scss";
import "../../scss/Gallery.scss";

// Dynamically import all images from the gallery directory
const importAll = (r) => {
    let images = {};
    r.keys().forEach((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
};
const images = importAll(require.context("../../images/gallery", false, /\.(png|jpe?g|svg)$/));

class Gallery extends Component {
    render() {
        const galleryItems = [
            { url: "https://endoftheroll.com/", imgName: "endoftheroll.jpg", altText: "End of the Roll", tags: ["Roketto", "Wordpress"] },
            { url: "https://okanaganspirits.com/", imgName: "okanaganspirits.jpg", altText: "Okanagan Spirits", tags: ["Roketto", "Wordpress", "Woocommerce"] },
            { url: "https://aurium.ca/", imgName: "aurium.jpg", altText: "Woodtone", tags: ["Freelance", "Wordpress"] },
            { url: "https://alphapoly.com/", imgName: "alphapoly.jpg", altText: "Alphapoly", tags: ["Freelance", "Wordpress"] },
            { url: "https://www.bluetreemortgages.com/", imgName: "bluetreemortgages.jpg", altText: "Woodtone", tags: ["Freelance", "Wordpress"] },
            { url: "https://www.xap.com/", imgName: "xap.jpg", altText: "XAP", tags: ["Roketto", "Custom"] },
            { url: "https://www.useractive.io/", imgName: "useractive.jpg", altText: "Useractive", tags: ["Roketto", "Wordpress", "Elementor"] },
            { url: "https://revelate.co/", imgName: "revelate.jpg", altText: "Revelate", tags: ["Roketto", "Wordpress"] },
            { url: "https://www.lumovest.com/", imgName: "lumovest.jpg", altText: "Lumovest", tags: ["Roketto", "Wordpress", "LMS (Learndash)"] },
            { url: "https://www.zohna.com/", imgName: "zohna.jpg", altText: "Zohna", tags: ["Roketto", "Wordpress"] },
            { url: "https://c60.ai/", imgName: "c60.jpg", altText: "C60", tags: ["Roketto", "Hubspot"] },
            { url: "https://ringy.com/", imgName: "ringy.jpg", altText: "Ringy", tags: ["Roketto", "Hubspot"] },
            { url: "https://www.altfeeco.com/", imgName: "altfee.jpg", altText: "Altfee Co", tags: ["Roketto", "Hubspot"] },
            { url: "https://integricare.ca/", imgName: "integricare.jpg", altText: "Integricare", tags: ["Roketto", "Hubspot", "Shopify"] },
            { url: "https://earthmoversmedia.com/", imgName: "earthmovers.jpg", altText: "Earthmovers", tags: ["Freelance", "Wordpress", "WP Bakery"] },
            { url: "https://sonyalooney.com/", imgName: "sonyalooney.jpg", altText: "Sonya Looney", tags: ["Freelance", "Wordpress", "WP Bakery"] },
            { url: "https://flsg.ca", imgName: "flsg.jpg", altText: "FLSG", tags: ["Roketto", "Hubspot"] },
            { url: "https://www.jaxbuilders.ca/", imgName: "jaxbuilders.jpg", altText: "Woodtone", tags: ["Roketto", "Hubspot"] },
            { url: "https://louskitchen.ca/", imgName: "louskitchen.jpg", altText: "Lou's Kitchen", tags: ["Crew Marketing Partners", "Wordpress"] },
            { url: "https://www.hansensoftware.com/", imgName: "hansensoftware.jpg", altText: "Hansen Software", tags: ["Crew Marketing Partners", "Concrete5"] },
            { url: "https://crewmarketingpartners.com/", imgName: "crewmarketingpartners.jpg", altText: "CREW Marketing Partners", tags: ["Crew Marketing Partners", "Wordpress"] },
            { url: "https://woodtone.com/", imgName: "woodtone.jpg", altText: "Woodtone", tags: ["Crew Marketing Partners", "Wordpress"] },
            { url: "https://valorfireplaces.com", imgName: "valor.jpg", altText: "Valor Fireplaces", tags: ["Crew Marketing Partners", "Custom"] },
            { url: "https://thisisvillagechurch.com", imgName: "village_church.jpg", altText: "Village Church", tags: ["Crew Marketing Partners", "Wordpress"] },
            { url: "https://hardbitechips.com/", imgName: "hardbite.jpg", altText: "Hardbite Chips", tags: ["Crew Marketing Partners", "Wordpress"] },
            { url: "https://carebook.com", imgName: "carebook.jpg", altText: "Carebook", tags: ["Freelance", "Wordpress"] },
            { url: "https://novacom.ca", imgName: "novacom.jpg", altText: "Novacom", tags: ["Crew Marketing Partners", "Wordpress"] },
            { url: "https://gillespieco.ca/", imgName: "gillespie.jpg", altText: "Gillespie Co", tags: ["Crew Marketing Partners", "Wordpress"] },
            { url: "https://giddyupinsurance.com", imgName: "giddyup.jpg", altText: "Giddyup", tags: ["Freelance", "Wordpress"] },
            { url: "http://procura.ca", imgName: "procura.jpg", altText: "Procura", tags: ["Crew Marketing Partners", "Wordpress"] },
            { url: "http://kelownafoodspecials.com/", imgName: "kelownafoodspecials.jpg", altText: "Kelowna Food Specials", tags: ["Personal", "Wordpress"], description: "A website geared towards those looking for cheap food deals in and around Kelowna, BC." }
        ];

        return (
            <section className="gallery block">
                <div className="container">
                    <div className="title">
                        <h2>SELECTED WORK</h2>
                    </div>
                    <ul className="row justify-content-center">
                        {galleryItems.map((item, index) => {
                            const imgSrc = images[item.imgName];
                            return (
                                <li key={index} className="col-lg-4 col-md-6 item">
                                    <a href={item.url} target="_blank" rel="noopener noreferrer">
                                        <img src={imgSrc} alt={item.altText} />
                                    </a>
                                    <ul className="tags">
                                        {item.tags.map((tag, tagIndex) => (
                                            <li key={tagIndex} className="credit">{tag}</li>
                                        ))}
                                    </ul>
                                    {item.description && <p>{item.description}</p>}
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </section>
        );
    }
}

export default Gallery;
