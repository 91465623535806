import React from 'react';
import ReactDOM from 'react-dom/client'; // Updated import
import './scss/index.scss';
import App from './js/components/App';
import CacheBuster from 'react-cache-buster'; // Import default export
import registerServiceWorker from './registerServiceWorker';

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement); // Create a root

root.render(
  <CacheBuster>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </CacheBuster>
);

registerServiceWorker();