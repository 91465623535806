import React, { Component } from 'react';
import Banner from './Banner';
import Gallery from './Gallery';
import About from './About';
// Deprecated activity
//import Activity from './Activity';
import Footer from './Footer';
import Form from './Form';

import '../../scss/App.scss';
import '../../scss/common/normalize.scss';
import '../../scss/common/global.scss';
import '../../scss/common/fonts.scss';

class App extends Component {
  render() {
    return (
      <div className="ih-app">
        <Banner />
        <About />
        <Gallery />
        <Form />
        <Footer />
      </div>
    );
  }
}

export default App;
