import React, { Component } from "react";
import "../../scss/Banner.scss";

class Banner extends Component {
    render() {
        return (
            <section className="banner">
                <header className="header">
                    <div className="brand">
                        <span className="logo">IH</span>
                    </div>
                    <div className="desc">
                        <h3>IAN HOYTE</h3>
                        <h1>Web Developer</h1>
                        <small>KELOWNA, BC</small>
                    </div>
                    <ul className="social">
                        <li>
                            <a href="https://twitter.com/ianhoyte" target="_blank" rel="noopener noreferrer">
                                <i className="icon-twitter" />
                            </a>
                        </li>
                        <li>
                            <a href="https://ca.linkedin.com/in/ian-hoyte-89551275" target="_blank" rel="noopener noreferrer">
                                <i className="icon-linkedin" />
                            </a>
                        </li>
                        <li>
                            <a href="https://github.com/ianhoyte" target="_blank" rel="noopener noreferrer">
                                <i className="icon-github" />
                            </a>
                        </li>
                    </ul>
                </header>
            </section>
        );
    }
}

export default Banner;
