import React, { Component } from 'react';
import '../../scss/Form.scss';

class Form extends Component {
  render() {

    return (
        <section className="block form">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                        <div className="row justify-content-center">
                            <div className="col-md-10 intro">
                                <h2>GET IN TOUCH</h2>
                                <p>If you have any questions about me or my work, please feel free to get in touch!</p>
                            </div>
                        </div>
                        <form action="https://formspree.io/i@nhoyte.com" method="POST">
                            <div className="row">
                                <div className="input col-md-6">
                                    <label htmlFor="name">Name</label>
                                    <input required type="text" name="name" autoComplete="off" />
                                </div>
                                <div className="input col-md-6">
                                    <label htmlFor="email">Email</label>
                                    <input required type="email" name="email" />
                                </div>
                            </div>
                            <div className="input">
                                <label htmlFor="body">What can I do to help you?</label>
                                <textarea required name="body"></textarea>
                            </div>
                            <div className="btn-wrap">
                                <button className="btn" type="submit">Let's Go!</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
  }
}

export default Form;
