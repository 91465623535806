import React, { Component } from 'react';
import '../../scss/Footer.scss';

class Footer extends Component {
  render() {

    var currentYear = new Date().getFullYear();

    return (
        <footer className="footer">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <span>&copy; {currentYear} - Ian Hoyte (unless otherwise noted)</span>
                    </div>
                </div>
            </div>
        </footer>
    );
  }
}

export default Footer;
